import React from 'react';

export default function Upload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 2.09 2.09"
    >
      <circle
        id="椭圆_3"
        data-name="椭圆 3"
        cx="1.004"
        cy="1.004"
        r="1.004"
        transform="translate(0.042 0.042)"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="0.083"
      />
      <path
        id="路径_67"
        data-name="路径 67"
        d="M108.7,223.577l-.492-.552-.492.552h.288v.3h.407v-.3Z"
        transform="translate(-107.164 -222.406)"
        fill="currentColor"
      />
    </svg>
  );
}
