import {
  Col,
  Row,
  Affix,
  Form,
  Input,
  Upload,
  message,
  Spin,
  notification,
  Button,
} from 'antd';
import clsx from 'clsx';
import UploadIcon from '../../svgs/upload';
import {Link} from 'gatsby';
import React, {useState} from 'react';
import ArrowRight from '../../svgs/arrow-right';
import * as styles from './index.module.scss';
import {directus} from '../../utils/directus';
import {UploadChangeParam} from 'antd/lib/upload';
import {UploadFile} from 'antd/lib/upload/interface';
import {useForm} from 'antd/lib/form/Form';
import {useTranslation} from 'react-i18next';
type PropTypes = {
  id?: string;
};
type FormValues = {
  name: string;
  contact: string;
  file: UploadChangeParam<UploadFile>;
};

export default function SectionJoinUs({id}: PropTypes) {
  const [isSuccess, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const form = useForm();
  const {t} = useTranslation('join-us');
  const PLACEHOLDERS = {
    name: t('您的姓名'),
    contact: t('联系方式'),
  };

  const onSubmit = async (values: FormValues) => {
    const formData = new FormData();
    formData.append('title', 'resume-' + values.name);

    if (values.file.file.originFileObj) {
      formData.append('file', values.file.file.originFileObj);
    }

    try {
      setLoading(true);
      const resumeFile = await directus.files.createOne(formData);
      await directus.items('resume').createOne({
        name: values.name,
        contact: values.contact,
        file: {
          id: resumeFile.id,
        },
      });
      setSuccess(true);
      message.success(t('简历提交成功'));
      form[0].resetFields();
    } catch (e) {
      console.error(e);
      notification.error({
        message: t('简历提交失败'),
        btn: (
          <Link to="/contact">
            <Button type="primary">{t('联系我们')}</Button>
          </Link>
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id={id} className={styles.section}>
      <Row>
        <Col span={0} sm={12} className={clsx(styles.title, styles.box)}>
          <div className={styles.content}>
            <div className={styles.left}>JOIN US</div>
            <div className={styles.right}>{t('加入我们')}</div>
          </div>
          <div className={styles.enter}>
            <ArrowRight />
          </div>
        </Col>
        <Col span={24} sm={12} className={clsx(styles.form, styles.box)}>
          <Form
            className={styles.form}
            form={form[0]}
            onFinish={onSubmit}
            onChange={() => setSuccess(false)}
            initialValues={{
              name: '',
              contact: '',
              file: null,
            }}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: t('请输入您的姓名'),
                },
              ]}
            >
              <Input
                className={styles.input}
                size="large"
                placeholder={PLACEHOLDERS.name}
              />
            </Form.Item>
            <Form.Item
              name="contact"
              rules={[
                {
                  required: true,
                  message: t('请输入联系方式'),
                },
              ]}
            >
              <Input
                className={styles.input}
                size="large"
                placeholder={PLACEHOLDERS.contact}
              />
            </Form.Item>
            <Form.Item
              className={styles.uploadContainer}
              name="file"
              rules={[
                {
                  required: true,
                  message: t('请上传简历'),
                },
              ]}
            >
              <Upload showUploadList={false} multiple={false}>
                <button
                  className={clsx('button-primary', styles.upload)}
                  type="button"
                >
                  {t('点击此处上传简历')}
                  <UploadIcon />
                </button>
              </Upload>
            </Form.Item>
            <Form.Item>
              {isLoading ? (
                <span>
                  <Spin />
                </span>
              ) : (
                <button
                  className={clsx('button-primary', styles.submit)}
                  type="submit"
                >
                  {t('提交')}
                </button>
              )}
              {isSuccess && (
                <span className={styles.success}>{t('提交成功！')}</span>
              )}
            </Form.Item>
          </Form>
          <div className={styles.tip}>
            {t('您的简历将提交至人力资源部，我司将尽快给您回复！')}
          </div>
        </Col>
      </Row>
    </section>
  );
}
