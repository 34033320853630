import {Col, Row} from 'antd';
import clsx from 'clsx';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './index.module.scss';

export type ViewModel = {
  grids: {
    title: string;
    imageUrl: string;
  }[];
};
type PropTypes = {
  id?: string;
  vm: ViewModel;
};
/** 每张卡片的宽度参数 */
const WIDTH_SIZES = [67, 33, 50, 50, 50];

export default function EmployeesActivity({vm, id}: PropTypes) {
  return (
    <section id={id} className={styles.section}>
      <StaticImage
        className={styles.bg}
        src="../../images/employees-activity-bg-1.jpg"
        alt=""
      />
      <div className={styles.grid}>
        {vm.grids.map((item, index) => (
          <div
            key={item.title}
            className={clsx(styles.item, index === 3 && styles.thick)}
            style={{
              backgroundImage: `url(${item.imageUrl})`,
              width: `${WIDTH_SIZES[index]}%`,
              float: index === 3 ? 'right' : 'left',
            }}
          >
            <div
              className={clsx(
                styles.content,
                [1, 3].includes(index) && styles.leftBottom
              )}
            >
              {item.title}
            </div>
          </div>
        ))}
      </div>
      <StaticImage
        className={styles.bg}
        src="../../images/employees-activity-bg-2.jpg"
        alt=""
      />
    </section>
  );
}
