// extracted by mini-css-extract-plugin
export var box = "index-module--box--K8Mtm";
export var content = "index-module--content--BJiID";
export var enter = "index-module--enter--nMahO";
export var form = "index-module--form--+lf7Q";
export var left = "index-module--left--+oszc";
export var right = "index-module--right--pakMt";
export var section = "index-module--section---T-4R";
export var submit = "index-module--submit--u2uv4";
export var success = "index-module--success--pg7NV";
export var tip = "index-module--tip--2d8nf";
export var title = "index-module--title--Ewycj";
export var upload = "index-module--upload--y3-A1";
export var uploadContainer = "index-module--upload-container--HPmjy";