import clsx from 'clsx';
import {graphql, PageProps} from 'gatsby';
import React from 'react';
import LuArticleHero from '../components/lu-article-hero';
import DefaultLayout from '../layouts/default';
import heroImage from '../images/news-hero.jpg';
import LuArticleToc from '../components/lu-article-toc';
import activity1Image from '../images/employees-1.jpg';
import activity2Image from '../images/employees-2.jpg';
import activity3Image from '../images/employees-3.jpg';
import activity4Image from '../images/employees-4.jpg';
import activity5Image from '../images/employees-5.jpg';
import EmployeesActivity, {
  ViewModel as EmployeesActivityViewModel,
} from '../components/employees-activity';
import SectionJoinUs from '../components/section-join-us';
import {useTranslation} from 'react-i18next';

const CareerPage = ({data, location}: PageProps) => {
  const {t} = useTranslation('career');
  const PAGE_DATA = {
    title: ['Staff', 'Activities'],
    heroImage: heroImage,
    subTitle: t('员工生活'),
    tabs: [
      {
        title: t('员工活动'),
        anchor: 'employee-1',
      },
      {
        title: t('加入我们'),
        anchor: 'employee-2',
      },
    ],
  };
  const activities: EmployeesActivityViewModel = {
    grids: [
      {
        title: t('篮球赛'),
        imageUrl: activity5Image,
      },
      {
        title: t('消防演习'),
        imageUrl: activity3Image,
      },
      {
        title: t('外出团建'),
        imageUrl: activity2Image,
      },
      {
        title: t('员工表彰'),
        imageUrl: activity4Image,
      },
      {
        title: t('文艺演出'),
        imageUrl: activity1Image,
      },
    ],
  };
  return (
    <DefaultLayout location={location}>
      <LuArticleHero
        title={PAGE_DATA.title}
        subTitle={PAGE_DATA.subTitle}
        bgImageUrl={PAGE_DATA.heroImage}
      />
      <LuArticleToc tabs={PAGE_DATA.tabs} />
      <EmployeesActivity id="employee-1" vm={activities} />
      <SectionJoinUs id="employee-2" />
    </DefaultLayout>
  );
};

export default CareerPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
